module.exports = [{
      plugin: require('/codebuild/output/src120103417/src/happy-hours/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src120103417/src/happy-hours/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src120103417/src/happy-hours/node_modules/gatsby-plugin-emotion/gatsby-browser'),
      options: {"plugins":[]},
    }]
